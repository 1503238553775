import React from 'react';
import { Hamburger, IconRewardsGift } from '../Icons';
import classes from './Navbar.module.scss';
import Link from 'next/link';
import { useWallets } from '~/store/walletContext/WalletContext';
import { useViewport } from '~/store/viewportContext/viewportContext';
import { useStore } from '~/store/store';
import { WalletDropdownMenu } from '~/components/Menus';
import Logo from '~/components/Logo';
import { SettingsModal } from '../Modals';
import { useAppStatus } from '~/store/appContext/hooks';
import { useLang } from '~/store/languageContext/hooks';

import dynamic from 'next/dynamic';
import Button from '../_ui-kit/Button';
import { useRouter } from 'next/router';

const Notifications = dynamic(() => import('~/modules/Notifications'));
interface Props {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

function Navbar({ setShowMenu }: Props) {
  const { t } = useLang();
  const { warningMode, maintenanceMode } = useAppStatus();
  const { state } = useStore();
  const { width } = useViewport();
  const { setShowWalletModal, showWalletModal } = useWallets();
  const router = useRouter();

  return (
    <header className={classes.Navbar}>
      <Logo noMargin />
      {/* Desktop screens */}
      {width > 1023 && (
        <nav className={classes.navbarMenu}>
          <ul className={classes.navLinks}>
            {navItems.map((link) => (
              <li key={`navItem-${link.label}`}>
                <Link href={link.href}>
                  <a target={link.blank ? '_blank' : ''}>
                    <span
                      className={`${classes.label} ${
                        router.pathname === link.href ? classes.selected : ''
                      }`}
                    >
                      {t(link.translation)}
                    </span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}

      {/* Desktop screens */}
      {!warningMode && !maintenanceMode && (
        // {width > 1023 && !warningMode && !maintenanceMode && (
        <>
          {state.isWalletConnected && (
            <>
              <ul className={classes.navLinks}>
                <li className={classes.iconNavMenu}>
                  <Link href={`/rewards/address/${state.userAddress}`}>
                    <a className={classes.rewardsButton}>
                      <IconRewardsGift />
                    </a>
                  </Link>
                </li>
                <li className={classes.iconNavMenu}>
                  <Notifications />
                </li>
              </ul>
              <WalletDropdownMenu />
            </>
          )}
          {width > 1023 && !state.isWalletConnected && (
            <Button
              className={`${!state.isWalletConnected ? classes.left : ''}`}
              size="m"
              onClick={() => setShowWalletModal(!showWalletModal)}
            >
              {t('Header:sign_in')}
            </Button>
          )}
        </>
      )}
      {/* Non desktop screens */}
      {width < 1024 && (
        <>
          {!state.isWalletConnected && (
            <nav className={`${classes.navbarMenu} ${classes.signIn}`}>
              <ul className={classes.navLinks}>
                <li>
                  <Button size="m" onClick={() => setShowWalletModal(!showWalletModal)}>
                    {t('Header:sign_in')}
                  </Button>
                </li>
              </ul>
            </nav>
          )}
          <button
            className={`${classes.button} ${classes.overrideBtn} ${
              state.isWalletConnected ? classes.left : ''
            }`}
            onClick={() => setShowMenu(true)}
          >
            <Hamburger className={classes.icon} />
          </button>
        </>
      )}

      {state.globalModal === 'settings' && <SettingsModal />}
    </header>
  );
}

export default React.memo(Navbar);

const navItems = [
  {
    label: 'Home',
    href: '/',
    translation: 'Header:home',
    blank: false,
  },
  {
    label: 'Marketplace',
    href: '/marketplace',
    translation: 'Header:marketplace',
    blank: false,
  },
  {
    label: 'Collections',
    href: '/collections',
    translation: 'Header:collections',
    blank: false,
  },
  // {
  //   label: 'Curators',
  //   href: '/curators',
  //   translation: 'Header:curators',
  //   blank: false,
  // },
  // {
  //   label: 'Editorial',
  //   href: '/editorial',
  //   translation: 'Header:editorial',
  //   blank: false,
  // },
  {
    label: 'About Mito',
    href: '/about',
    translation: 'Header:about_mito',
    blank: true,
  },
];
